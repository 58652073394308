/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/styles/blob/master/LICENSE)
 */

 @import "styles/functions";

 @import "bootstrap-override";
 @import "app-variables";
 
 @import "styles/variables";
 @import "styles/mixins";
 
 @import "styles/root";
 @import "styles/reboot";
 @import "styles/type";
 @import "styles/images";
 @import "styles/code";
 @import "styles/grid";
 @import "styles/tables";
 @import "styles/forms";
 @import "styles/buttons";
 @import "styles/transitions";
 @import "styles/dropdown";
 @import "styles/button-group";
 @import "styles/input-group";
 @import "styles/custom-forms";
 @import "styles/nav";
 @import "styles/navbar";
 @import "styles/card";
 @import "styles/breadcrumb";
 @import "styles/pagination";
 @import "styles/badge";
 @import "styles/jumbotron";
 @import "styles/alert";
 @import "styles/progress";
 @import "styles/media";
 @import "styles/list-group";
 @import "styles/close";
 @import "styles/modal";
 @import "styles/tooltip";
 @import "styles/popover";
 @import "styles/carousel";
 @import "styles/utilities";
 @import "styles/print";
 
 // Bootstrap Rtl File
 @import "styles/rtl";